import * as React from 'react'
import {Component} from 'react'
import {connect} from 'react-redux';
import {RouteComponentProps} from "react-router-dom";
import {Input, Checkbox, Select, DatePicker, notification, Icon, Modal, Button, Form} from 'antd-min';
import moment from 'moment';
import {
    withRouter,
    GLUtil,
    GLGlobal,
    GLForm,
    GLFormComponentProps,
    FormHelper,
    FormItemsLayout,
    ComparisonOperator,
    alignPop,
    beforeDate,
    LanguageDateFormat,
    GLLocale,
} from 'gl-commonui';
import {PathConfig} from '@app/config/pathconfig';
import {SchoolLocale} from "@app/locales/localeid";
import {SecondaryTitle, SaveSubmitBtns, ThirdTitle, Container} from '@app/components';
import {PurchaseRequestProps, SubscriptionClass, PurchaseRequest} from './components';
import {ProductModel} from '@app/service/school/product';
import {ISchoolService, SchoolModel} from '@app/service/schools';
import {
    MaterialModel,
    MaterialPropsModel,
    RequestProductItemModel,
    MaterialService,
    SubscriptionItemDisplayModel,
    CumulativeGridItemModel
} from '@app/service/material'
import {StateType} from '@app/states';
import material, {
    create,
    getMaterialCart,
    formatMaterialCart,
    getMaterialOrder4Cart,
    createMaterialRequest,
    getConditions
} from '@app/states/material';
import {
    textareaValider,
    extendForm,
    CommonHelper,
    PurchaseTarget,
    setQuery,
    MaterialHelper,
    lazyInject,
    TYPES,
    formatToMaterialRequestId,
    fmtMsg,
    newLineRegex
} from '@app/util';
import {getUnitText} from "@app/page/school/material/util";
import { CumulativeProductGrid } from './components/CumulativeProductGrid';

const formItemLayoutLeft = {
    labelCol: {xm:{span:5}, md:{span:10}},
    wrapperCol: {xm:{span:7}, md:{span:14}}
};

const FormItem = Form.Item;

interface MaterialCreateProps extends RouteComponentProps<any> {
    loading?: boolean
    langLoaded?: string
    model: MaterialModel
    school: SchoolModel
    campuses?: any[]
    products?: ProductModel[]
    subscriptions?: any[]
    purchases?: RequestProductItemModel[]
    cumulativeData?: CumulativeGridItemModel[]
    saveButtonTextResourceId?: string
    getMaterialCart: (campusId) => void
    create: (material) => void
    createMaterialRequest: (material) => void
    formatMaterialCart: (params) => void
    getMaterialOrder4Cart: (params) => void
    conditions: string
    getConditions: () => void
}

interface MaterialCreateStates {
    campusId?: string
    campusOptions?: JSX.Element[] | null,
    schoolShoppingCart?: boolean, // true if users access this page from school breadcrumb
    isTermsModalVisible?: boolean,
}

@withRouter
@connect(({intl: {langLoaded}, material: {loading, model, school, campuses, subscriptions, purchases, cumulativeData, conditions}}: StateType) => (
        {loading, langLoaded, model, school, campuses, subscriptions, purchases, cumulativeData, conditions}),
    {create, getMaterialCart, formatMaterialCart, getMaterialOrder4Cart, createMaterialRequest, getConditions}
)
@GLForm.create()
export class MaterialNewPage extends Component<MaterialCreateProps & RouteComponentProps<any> & GLFormComponentProps, MaterialCreateStates> {
    materialService: MaterialService
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService;

    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.materialService = new MaterialService();
        this.handleCampusChange = this.handleCampusChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.emptyFunc = this.emptyFunc.bind(this);
    }

    getCampusOptions(campuses) {
        return campuses.filter(camp => {return !camp.disabled}).map((campus, index) => {
            return CommonHelper.getOption(campus.id, campus.id, campus.name)
        });
    }

    componentWillReceiveProps(nextProps) {
        const {campusId, orderId} = nextProps.match.params;
        if (!campusId) {
            return;
        }
        if (this.state.campusId && this.state.campusId != campusId) {
            this.setState({campusId: campusId}, () => {
                this.props.getMaterialCart(campusId);
                this.props.form.resetFields();
            });
        }
        if (nextProps.campuses && nextProps.campuses.data) {
            this.setState({campusOptions: this.getCampusOptions(nextProps.campuses.data)});
        }
        if (this.props.langLoaded != nextProps.langLoaded) {
            this.props.formatMaterialCart({materialCart: null});
        }
        if (orderId && nextProps.model.id && MaterialHelper.MaterialOrderStatus[nextProps.model.status] != SchoolLocale.MaterialOrderStatusDraft) {
            const {regionId, schoolId, campusId} = this.props.match.params;
            const orderPath = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.CampusOrdersItem, {
                regionId,
                schoolId,
                campusId,
                orderId
            }) : '';
            this.props.history.push(orderPath)
        }
    }

    componentDidMount() {
        const {schoolId, campusId, orderId} = this.props.match.params;
        // if users access from campus menu
        if (campusId) {
            this.setState({campusId: campusId});
            if (orderId) {
                this.props.getMaterialOrder4Cart({schoolId, campusId, orderId});
            } else {
                this.props.getMaterialCart(campusId);
            }
        } else {
            // if users access from school menu
            this.setState({schoolShoppingCart: true});
            this.getCampusesBySchoolId(schoolId);
        }
    }

    getCampusesBySchoolId = (schoolId) => {
        this.schoolService.getAccessibleCampuses(schoolId, false).then(response => {
            this.setState({campusOptions: this.getCampusOptions(response)});
        });
    }

    handleCampusChange(campusId) {
        const {regionId, schoolId} = this.props.match.params;
        if (this.state.schoolShoppingCart) {
            this.setState({campusId: campusId}, () => {
                this.props.getMaterialCart(campusId);
                this.props.form.resetFields();
            });
        } else {
            this.props.history.push({
                pathname: GLUtil.pathStringify(PathConfig.CampusCart, {
                    regionId,
                    schoolId,
                    campusId
                })
            });
        }
    }

    lengthValidator(localeId, rightVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rightVal)];
    }

    noteValidator(form) {
        return textareaValider(form, MaterialPropsModel.notes).concat(this.lengthValidator(SchoolLocale.MaterialRequestFieldNote, 250));
    }

    addressValidator(form) {
        return textareaValider(form, MaterialPropsModel.address).concat(this.lengthValidator(SchoolLocale.MaterialRequestFieldShippingAddress, 500));
    }

    emailValidator() {
        let rules = [];
        rules.push(FormHelper.ruleForCompareLength(SchoolLocale.MaterialRequestFieldEmail, ComparisonOperator.LessOrEqualsThan, 120));
        rules.push(FormHelper.ruleForEmail(SchoolLocale.MaterialRequestFieldEmail));
        return rules;
    }

    reloadCampus = () => {
        this.setState({campusId: this.state.campusId}, () => {
            this.props.getMaterialCart(this.state.campusId);
            this.props.form.resetFields();
        });
    }

    showToast = (id: number, detailPath: string) => {
        const description = GLGlobal.intl.formatMessage(
            { id: SchoolLocale.MaterialRequestMessageSubmittedSuccessfully },
            { materialRequestId: `<a href="${detailPath}">${formatToMaterialRequestId(id)}</a>` }
        );
        notification.open({
            message: GLGlobal.intl.formatMessage({ id: GLLocale.MessageTitleSuccess }),
            description: <div dangerouslySetInnerHTML={{ __html: description }} />,
            icon: <Icon type="check-circle" style={{ color: "#52c41a" }} theme="filled" />,
        });
    }

    save(param: { isSubmit: boolean }) {
        const {orderId} = this.props.match.params;
        setQuery('classes', {current: 1}, {campusId: this.state.campusId});
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.shipDate = values.shipDate ? moment(values.shipDate).format('YYYY-MM-DD') : null;
                if (this.state.schoolShoppingCart) {
                    const payload = {
                        data: {
                            ...values,
                            isSubmit: param.isSubmit,
                            id: orderId,
                            regionId: this.props.match.params.regionId,
                            schoolId: this.props.match.params.schoolId,
                            campusId: this.state.campusId,
                        },
                        reloadCampus: this.reloadCampus,
                        showToast: this.showToast,
                    }
                    this.props.createMaterialRequest(payload);
                } else {
                    this.props.create({...values, isSubmit: param.isSubmit, id: orderId, showToast: this.showToast});
                }
            }
        });
    }

    handleSave(e) {
        e.preventDefault();
        this.save({isSubmit: false});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.save({isSubmit: true});
    }

    openTermsModal = () => {
        this.props.getConditions();
        this.setState({isTermsModalVisible: true});
    }

    closeTermsModal = () => {
        this.setState({isTermsModalVisible: false});
    }

    formatData(subscriptions: SubscriptionItemDisplayModel[]) {
        return subscriptions.map(subscription => ({
            ...subscription,
            unitText: getUnitText(subscription.unit)
        }))
    }

    /*for setting the setGridRef function to be empty */
    emptyFunc() {
    } 

    render() {
        const {schoolId, orderId} = this.props.match.params;
        let campusId = this.props.match.params.campusId;
        const {form, model: material, school, saveButtonTextResourceId, history, location, match, cumulativeData} = this.props;
        if (this.state.campusId) {
            campusId = this.state.campusId;
            match.params.campusId = campusId;
        }
        const isMobile = CommonHelper.isMobile();
        const renderFormItem = FormHelper.renderFormItem;
        const purchaseProps: PurchaseRequestProps = {
            canEdit: school.allowMaterialRequest,
            locationParams: this.props.match.params,
            productDataParams: {schoolId, campusId, orderId, subscriptionType: school.subscriptionType},
            purchaseTarget: PurchaseTarget.School,
            startUnit: 1,
            maxUnit: school.maxUnit,
            requestTitleId: SchoolLocale.MaterialPurchaseItemListTitle,
            editRequestIconTitleId: SchoolLocale.MaterialPurchaseItemListIconEdit,
            deleteRequestIconTitleId: SchoolLocale.MaterialPurchaseItemListIconDelete,
            needClassColumn: true,
            needPriceColumn: true,
            requests: this.props.purchases,
            history,
            location,
            match,
            getRequestItem: this.materialService.getPurchaseItem.bind(this.materialService),
            needTotalPriceColumn: false,
            showNoteColumn: true,
            needTotalRow:true
        }
        const disableCampus = orderId != undefined;
        const materialAddress = material.address ? material.address.split(newLineRegex) : [];
        const materialAddressDisplay = materialAddress.map(addressFragment => (<span>{addressFragment}<br/></span>))
        return (
            <Container>
                <div className="material material-new">
                    <SecondaryTitle title={SchoolLocale.MaterialPageTitle}/>
                    <GLForm>
                        {/*address*/}
                        <ThirdTitle title={SchoolLocale.MaterialRequestFieldShipTitle}/>
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldCampus, MaterialPropsModel.campusId,
                                <Select size='large' disabled={disableCampus} {...alignPop()}
                                        showSearch={!isMobile}
                                        filterOption={
                                            (input, option) => {
                                                return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }
                                        }
                                        onChange={this.handleCampusChange}>{...this.state.campusOptions}
                                </Select>, this.state.campusOptions && campusId, true)}
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldName, MaterialPropsModel.shippingName,
                                <Input/>, material.shippingName, false, this.lengthValidator(SchoolLocale.MaterialRequestFieldName, 120))}
                        </FormItemsLayout>
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldPostCode, MaterialPropsModel.postalCode,
                                <Input/>, material.postalCode, true, this.lengthValidator(SchoolLocale.MaterialRequestFieldPostCode, 200))}
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldEmail, MaterialPropsModel.shippingEmail,
                                <Input/>, material.shippingEmail, false, this.emailValidator())}
                        </FormItemsLayout>
                        <FormItemsLayout colTotal={2}>
                            <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldShippingAddress})}>
                                <div className='readonly-text'>{materialAddressDisplay}</div>
                            </FormItem>
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldPhone, MaterialPropsModel.shippingPhone,
                                <Input/>, material.shippingPhone, false, this.lengthValidator(SchoolLocale.MaterialRequestFieldPhone, 30))}
                        </FormItemsLayout>
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldShipDate, MaterialPropsModel.shipDate,
                                <DatePicker {...alignPop({type: 'DatePicker', querySelector: '.ant-form'})}
                                            format={LanguageDateFormat[GLGlobal.intl.locale]}
                                            disabledDate={beforeDate(moment().add(-1, 'days').endOf('day'))}
                                />, material.shipDate ? moment(material.shipDate) : null)}
                        </FormItemsLayout>

                        {/*contact*/}
                        <ThirdTitle title={SchoolLocale.MaterialRequestFieldSubmissionTitle}/>
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldSubmissionUser, MaterialPropsModel.submissionUser,
                                <Input/>, material.submissionUser, true, this.lengthValidator(SchoolLocale.MaterialRequestFieldName, 120))}
                        </FormItemsLayout>
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldSubmissionEmail, MaterialPropsModel.submissionEmail,
                                <Input/>, material.submissionEmail, true, this.emailValidator())}
                        </FormItemsLayout>
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(form, SchoolLocale.MaterialRequestFieldSubmissionPhone, MaterialPropsModel.submissionPhone,
                                <Input/>, material.submissionPhone, false, this.lengthValidator(SchoolLocale.MaterialRequestFieldPhone, 30))}
                        </FormItemsLayout>

                        {/*subscription*/}
                        <SubscriptionClass
                            canEdit={school.allowMaterialRequest}
                            locationParams={this.props.match.params}
                            subscriptions={this.formatData(this.props.subscriptions)}
                            needTotalRow={true}
                        />
                        {school.hasPurchaseProduct && <PurchaseRequest {...purchaseProps} />}
                        <CumulativeProductGrid orderId={""} setGridRef={this.emptyFunc} dataSource={cumulativeData}/>
                        {renderFormItem(extendForm(form), SchoolLocale.MaterialRequestFieldNote, MaterialPropsModel.notes,
                            <Input.TextArea/>, material.notes, false, this.noteValidator(form))}
                        {material.regionRequireAgreement &&
                            <div className="material-order-agreement-checkbox">
                                {renderFormItem(
                                    extendForm(form),
                                    SchoolLocale.MaterialRequestFieldAgreeToMaterialRequestLicense,
                                    MaterialPropsModel.agreeToMaterialRequestLicense,
                                    <Checkbox defaultChecked={material.agreeToMaterialRequestLicense}>
                                        <span>{fmtMsg({ id: SchoolLocale.MaterialRequestFieldAgreeToMaterialRequestLicense })} </span>
                                        <a href="javascript:" onClick={this.openTermsModal}>
                                            {fmtMsg({ id: SchoolLocale.MaterialRequestFieldViewTermsAndConditions })}
                                        </a>
                                    </Checkbox>,
                                    material.agreeToMaterialRequestLicense,
                                    false)}
                            </div>}
                        {school.allowMaterialRequest &&
                        <SaveSubmitBtns saveTitle={saveButtonTextResourceId} onSave={this.handleSave}
                                        onSubmit={this.handleSubmit}
                                        isSubmitDisabled={material.regionRequireAgreement && !form.getFieldValue(MaterialPropsModel.agreeToMaterialRequestLicense)}/>}
                    </GLForm>
                </div>
                <Modal
                    title={fmtMsg({ id: SchoolLocale.MaterialRequestTermsModalTermsAndConditions })}
                    centered
                    visible={this.state.isTermsModalVisible}
                    onCancel={this.closeTermsModal}
                    footer={<Button type="ghost" onClick={this.closeTermsModal}>{fmtMsg({ id: SchoolLocale.MaterialRequestTermsModalClose })}</Button>}
                    width={1000}
                >
                    <div dangerouslySetInnerHTML={{__html: this.props.conditions}}></div>
                </Modal>
            </Container>
        );
    }
}
