import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Select, Table } from 'antd-min';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { withRouter, GLUtil, GLGlobal, FormHelper, alignPop } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { SchoolLocale } from "@app/locales/localeid";
import { SecondaryTitle, Container } from '@app/components';
import { MaterialOrdersModel, MaterialOrdersPropsModel } from '@app/service/material'
import { StateType } from '@app/states';
import { getMaterialOrders } from '@app/states/material';
import { CommonHelper, DateHelper, MaterialHelper, fmtMsg, formatToMaterialRequestId } from '@app/util';
import { WijmoGrid } from "@app/components/grid";

const { Grid, Column } = WijmoGrid;

interface CampusOrdersProps extends RouteComponentProps<any> {
    loading?: boolean
    campuses?: any[]
    orders?: MaterialOrdersModel[]
    getMaterialOrders: ({schoolId, campusId}) => void
}
interface CampusOrdersStates {
    campusId?: string
    campusOptions?: JSX.Element[] | null
}

class OrdersTable extends Table<any> { }

@withRouter
@connect(({material: {loading, campuses, orders}}: StateType)=>(
        {loading, campuses, orders}),
        { getMaterialOrders }
    )
export class CampusOrdersPage extends Component<CampusOrdersProps & RouteComponentProps<any>, CampusOrdersStates> {
    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.handleCampusChange = this.handleCampusChange.bind(this);
    }

    getNotesColumnWidth = () => window.innerWidth - (460 + 80); // 460 for other 3 columns and 80 for padding of container
    getColumns(): any[] {
        let columns: ColumnProps<MaterialOrdersModel>[] = [
            {
                title: fmtMsg({ id: SchoolLocale.MaterialOrdersItemFieldOrderDate }),
                dataIndex: MaterialOrdersPropsModel.materialRequestIdText,
                render: (text, record, index) => {
                    const {regionId, schoolId, campusId} = this.props.match.params;
                    const orderPath = MaterialHelper.MaterialOrderStatus[record.status] == SchoolLocale.MaterialOrderStatusDraft ?
                        GLUtil.pathStringify(PathConfig.CampusOrdersDraftItem, { regionId, schoolId, campusId, orderId: record.id }) :
                        GLUtil.pathStringify(PathConfig.CampusOrdersItem, { regionId, schoolId, campusId, orderId: record.id });
                    return (
                        <a href={orderPath}>
                            {text}
                        </a>
                    )
                },
                key:"id",
                maxWidth: 160,
            },
            {
                title: fmtMsg({ id: SchoolLocale.MaterialOrderPagerFieldSubmissionDate }),
                dataIndex: MaterialOrdersPropsModel.submitDate,
                render: (text, record, index) => {
                    return DateHelper.formatDate2Local(record.submitDate)
                },
                align: "right",
                key:"submitDate",
                maxWidth: 150,
            },
            {
                title: fmtMsg({ id: SchoolLocale.MaterialOrdersItemFieldStatus }),
                dataIndex: MaterialOrdersPropsModel.statusText,
                maxWidth: 150,
                key:"status"
            },
            {
                title: fmtMsg({ id: SchoolLocale.MaterialOrdersItemFieldNotes }),
                dataIndex: MaterialOrdersPropsModel.notes,
                render: (text, record, index) => {
                    // Pre tags are not removed because of the issue fixed in - GL-3033 Line breaks on on material requests are lost
                    if (text && text.length) {
                        return <pre>{text}</pre>;
                    } else {
                        return <div></div>
                    }
                },
                key:"notes",
                maxWidth: this.getNotesColumnWidth(),
                wordWrap: true
            }
        ];
        return columns;
    }
    getCampusOptions(campuses) {
        return campuses.map((campus, index)=> {
            return CommonHelper.getOption(campus.id, campus.id, campus.name)
        });
    }
    componentWillReceiveProps(nextProps) {
        const {schoolId, campusId} = nextProps.match.params;
        if (!campusId) {return;}
        if (this.state.campusId && this.state.campusId != campusId) {
            this.setState({campusId: campusId}, () => { this.props.getMaterialOrders({schoolId, campusId}); });
        }
        if (nextProps.campuses && nextProps.campuses.data) {
            this.setState({ campusOptions: this.getCampusOptions(nextProps.campuses.data) });
        }
    }
    componentDidMount() {
        const {schoolId, campusId} = this.props.match.params;
        this.setState({campusId:campusId});
        this.props.getMaterialOrders({schoolId, campusId});
    }
    handleCampusChange(campusId) {
        const {regionId, schoolId} = this.props.match.params;
        this.props.history.push({ pathname: GLUtil.pathStringify(PathConfig.CampusOrders, { regionId, schoolId, campusId })});
    }
    formatData(data) {
        return data.map((record) => ({
            ...record,
            statusText: fmtMsg({id: MaterialHelper.MaterialOrderStatus[record.status]}),
            materialRequestIdText: formatToMaterialRequestId(record.materialRequestId),
        }))
    }

    render() {
        const isMobile = CommonHelper.isMobile();
        return (
            <Container fullWidth>
                <div className="material material-order">
                    <SecondaryTitle title={SchoolLocale.MaterialOrdersPageTitle} />
                    <div className='campus-select'>
                        <div><span><FormattedMessage id={SchoolLocale.ClassCampusSelect} /> : </span></div>
                        <div>
                            <Select size='large' {...alignPop()}
                                showSearch={!isMobile}
                                filterOption={
                                    (input, option) => {
                                        return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                }
                                onChange={this.handleCampusChange}
                                value={this.state.campusOptions && this.state.campusId}>{...this.state.campusOptions}
                            </Select>
                        </div>
                    </div>
                    <div className="material-order__gridcontainer">
                        <Grid
                            itemsSource={this.formatData(this.props.orders)}
                            allowSorting={false}
                            className="material-order__grid"
                            autoRowHeights={true}
                        >
                            {
                                this.getColumns().map(column =>{
                                    return  <Column
                                        renderHeader={()=> column.title}
                                        binding={column.dataIndex}
                                        render={column.render}
                                        key={column.key}
                                        wordWrap={column.wordWrap}
                                    />
                                })
                            }
                        </Grid>
                    </div>
                </div>
            </Container>
        );
    }
}
