import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from "react-router-dom";
import { Form } from 'antd-min';
import { withRouter, GLGlobal, GLForm, GLFormComponentProps, FormItemsLayout, GLUtil } from 'gl-commonui';
import { SchoolLocale } from "@app/locales/localeid";
import { PathConfig } from '@app/config/pathconfig';
import { SecondaryTitle, Container } from '@app/components';
import { SubscriptionRequestProps, SubscriptionRequest, PurchaseRequestProps, PurchaseRequest4Class } from './components';
import { SubscriptionRequestModel, SubscriptionMaterialService, MaterialService } from '@app/service/material'
import { SchoolClassModel, CurriculumType, SubscriptionType, SubscriptionTypeText } from "@app/service/class";
import { StateType } from '@app/states';
import { getClassCart, formatSubscriptionCart } from '@app/states/material';
import { get as getSchool } from '@app/states/school/school';
import { get, getLicense } from '@app/states/school/class';
import { PurchaseTarget, DateHelper, fmtMsg, LicenseTypeValueNameMap, CONSTS } from '@app/util';
import { LicensePageProps } from '../class'
import { SchoolModel } from '@app/service/schools';

interface ClassCreateProps extends LicensePageProps {
    loading?: boolean
    langLoaded?: string
    subscriptionRequest?: SubscriptionRequestModel
    subscriptions?: any[]
    purchases?: any[]
    school?: SchoolModel
    schoolClass?: SchoolClassModel
    getSchool: (schoolId) => void
    getClass: (classId) => void
    getClassCart: (classId) => void
    formatSubscriptionCart: (params) => void
}
interface ClassCreateStates {
    campusOptions?: JSX.Element[] | null
}

@withRouter
@connect(({
        intl: { langLoaded },
        material: {subscriptionRequest, subscriptions, purchases}, 
        schoolClass: { model, license: { counts, period, billedFrom, curriculumType, subTypeLable, subType, lastPeriod } }, 
        school: { current: school }
    }: StateType)=>(
        {langLoaded, subscriptionRequest, subscriptions, purchases, schoolClass: model, curriculumType, subTypeLable, subType, counts, period, billedFrom, lastPeriod, school}), 
        {getSchool, getClass: get, getLicense, getClassCart, formatSubscriptionCart}
)
export class ClassNewPage extends Component<ClassCreateProps & RouteComponentProps<any>, ClassCreateStates> {
    materialService: MaterialService
    subscriptionService: SubscriptionMaterialService
    constructor(props, context) {
        super(props, context);
        this.materialService = new MaterialService();
        this.subscriptionService = new SubscriptionMaterialService();
        this.state = {};
    }
    componentWillReceiveProps(nextProps) {
        nextProps.school.id !== this.props.school.id && this.props.getLicense(this.props.match.params);
        if (this.props.langLoaded != nextProps.langLoaded) {
            this.props.getLicense(this.props.match.params);
            this.props.formatSubscriptionCart({materialCart: null}); 
        }
    }    
    componentDidMount() {
        const {match: { params }} = this.props;
        this.props.getSchool({schoolId: params.schoolId});
        this.props.getClass({ schoolId: params.schoolId, id: params.classId });
        this.props.school.id && this.props.getLicense(params);
        this.props.getClassCart(params);
    }
    formatSchoolClass(schoolClass) {
        schoolClass.currentUnit = Math.abs(schoolClass.currentUnit);
        schoolClass.maxUnit = Math.abs(schoolClass.maxUnit);
        return schoolClass;
    }
    render() {
        const {schoolId, campusId} = this.props.match.params;
        const schoolClass = this.formatSchoolClass(this.props.schoolClass);
        const { school, subType, subTypeLable, counts, period, lastPeriod, curriculumType } = this.props;
        const subTypeN = subType == undefined ? null : parseInt(subType, 10);
        const nextUnitStartDate = schoolClass && schoolClass.nextUnitStartDate ? DateHelper.formatDate2Local(schoolClass.nextUnitStartDate) : '';
        const licenseTypeN = curriculumType == undefined ? null : parseInt(curriculumType, 10);
        const quantity = subTypeN != null ? counts.sub.newCnt : 0;
        const startUnit = schoolClass.currentUnit ? schoolClass.currentUnit : schoolClass.startUnit;
        const maxUnit = schoolClass.nextUnit ? schoolClass.nextUnit : startUnit;
        const subscriptionProps: SubscriptionRequestProps = {
            canEdit: true,
            locationParams: this.props.match.params,
            productDataParams: {schoolId: schoolId, class: schoolClass, subscriptionType: subTypeN},
            purchaseTarget: PurchaseTarget.Class,
            startUnit: startUnit,
            maxUnit: maxUnit,
            requestTitleId: SchoolLocale.MaterialSubscriptionItemListTitle,
            editRequestIconTitleId: SchoolLocale.MaterialSubscriptionItemListIconEdit,
            deleteRequestIconTitleId: SchoolLocale.MaterialSubscriptionItemListIconDelete,
            requests: this.props.subscriptions,
            requestId: this.props.subscriptionRequest.id,
            getRequestItem: this.subscriptionService.getSubscriptionItem.bind(this.subscriptionService),
            defaultRequest: {unit: schoolClass.nextUnit, quantity: quantity},
            showNoteColumn: false,
            needTotalRow:true
        }
        const purchaseProps: PurchaseRequestProps = {
            canEdit: true,
            locationParams: this.props.match.params,
            productDataParams: {schoolId, campusId, class: schoolClass, subscriptionType: this.props.school.subscriptionType},
            purchaseTarget: PurchaseTarget.ClassSchool,
            startUnit: startUnit,
            maxUnit: maxUnit,
            requestTitleId: SchoolLocale.MaterialPurchaseItemListTitle,
            editRequestIconTitleId: SchoolLocale.MaterialPurchaseItemListIconEdit,
            deleteRequestIconTitleId: SchoolLocale.MaterialPurchaseItemListIconDelete,
            requests: this.props.purchases,
            getRequestItem: this.materialService.getPurchaseItem.bind(this.materialService),
            defaultRequest: {unit: schoolClass.nextUnit, quantity: quantity},
            showNoteColumn: true,
            needTotalRow:true
        }        
        const className = schoolClass.name ? `(${schoolClass.name})` : '';
        const title = `${fmtMsg({id:SchoolLocale.MaterialPageTitle})}${className}`;
        const licenseTypeLabelText = schoolClass && schoolClass.licenseType && fmtMsg({id : CONSTS.LicenseType.get(schoolClass.licenseType)});
        return (
            <Container fullWidth>
                <div className="material material-class">
                    <SecondaryTitle plain={title} />
                    <ClassSubscription
                        subscriptionType={subTypeN}
                        licenseType={licenseTypeN}
                        licenseTypeLable={licenseTypeLabelText}
                        lastBilledPeriod = {lastPeriod}
                        period={period && period()}
                        studentCount={counts && counts.sub}
                        licenseCount={counts && counts.license}
                        currentUnit={schoolClass.currentUnit ? schoolClass.currentUnit : fmtMsg({ id: SchoolLocale.TextNone })}
                        nextUnit={{unit: schoolClass.nextUnit? schoolClass.nextUnit : fmtMsg({ id: SchoolLocale.TextNone }), 
                            startDate: nextUnitStartDate
                        }}
                        locationParams={this.props.match.params}
                    />
                    <SubscriptionRequest {...subscriptionProps} />
                    {school.hasPurchaseProduct && <PurchaseRequest4Class {...purchaseProps} />}
                </div>
            </Container>
        );
    }
}

interface ClassSubscriptionProps {
    subscriptionType: number
    licenseType: number
    licenseTypeLable: string
    lastBilledPeriod: string
    period: string
    studentCount: {cnt, newCnt}
    licenseCount: {cnt, newCnt}
    currentUnit: string
    nextUnit: {unit: string, startDate: string}
    locationParams: any
}

@GLForm.create()
class ClassSubscription extends Component<ClassSubscriptionProps & GLFormComponentProps> {
    render() {
        const { subscriptionType, licenseType, licenseTypeLable, period, studentCount, licenseCount, 
            currentUnit, nextUnit, locationParams: {regionId, schoolId, campusId, classId} } = this.props;
        const isDigital = subscriptionType == SubscriptionType.Digital;
        const studentCountText = fmtMsg({id:SchoolLocale.MaterialSubscriptionClassItemLicenseStudentCnt});
        const studentCountValue = subscriptionType != null ? studentCount.newCnt : '';
        const subscriptionTypeText = subscriptionType !=null ? fmtMsg({id:SubscriptionTypeText[SubscriptionType[1-subscriptionType]]}) : '';
        const licenseCountText =  subscriptionType != null && licenseType == CurriculumType.GrapeSEED ? fmtMsg({id:SchoolLocale.MaterialSubscriptionClassItemLicenseCount}, {type:subscriptionTypeText}) : '';
        const licenseCountValue = subscriptionType != null && licenseType == CurriculumType.GrapeSEED ? licenseCount.newCnt : '';
        const FormItem = Form.Item;
        const formItemLayout = {
            labelCol: { xs: 24, sm: 14, md: 13 },
            wrapperCol: { xs: 24, sm: 10, md: 10 }
        };
        return (
            <div className='info'>
                <FormItemsLayout colTotal={2}>
                    <FormItem {...formItemLayout} label={fmtMsg({id:SchoolLocale.ClassLicenseType})}>
                        <span className="ant-form-text">{licenseTypeLable}</span>
                    </FormItem>
                    <FormItem labelAlign='right' {...formItemLayout} colon={false} label={(
                        <Link className='unit-plan' to={GLUtil.pathStringify(PathConfig.ClassUnitPlan, { regionId, schoolId, campusId, classId })}>
                            {fmtMsg({id:SchoolLocale.UnitPlanTitle})}
                        </Link>
                    )}
                    >
                    </FormItem>
                </FormItemsLayout>
                <FormItemsLayout colTotal={2}>
                    <FormItem {...formItemLayout} label={studentCountText}>
                        <span className="ant-form-text">{studentCountValue}</span>
                    </FormItem>
                    <FormItem {...formItemLayout} label={fmtMsg({id:SchoolLocale.ClassUnit})}>
                        <span className="ant-form-text">{currentUnit}</span>
                    </FormItem>
                </FormItemsLayout>
                <FormItemsLayout colTotal={2}>
                    <FormItem {...formItemLayout} label={licenseCountText}>
                        <span className="ant-form-text">{licenseCountValue}</span>
                    </FormItem>
                    <FormItem {...formItemLayout} label={fmtMsg({id:SchoolLocale.MaterialSubscriptionClassItemNextUnit})}>
                        <span className="ant-form-text">{nextUnit.unit}</span>
                    </FormItem>
                </FormItemsLayout>
                <FormItemsLayout colTotal={2}>
                    {/* rendering empty item to align second item properly */}
                    <FormItem {...formItemLayout} label=''>
                        <span className="ant-form-text"></span>
                    </FormItem>
                    <FormItem {...formItemLayout} label={fmtMsg({id:SchoolLocale.MaterialSubscriptionClassItemNextUnitStartDate})}>
                        <span className="ant-form-text">{nextUnit.startDate}</span>
                    </FormItem>               
                </FormItemsLayout>
            </div>            
        );
    }
}