import React from 'react'
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from "antd-min";
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface UserGroupProps {
    form: WrappedFormUtils
    formFieldName: string
    groupTitleId: string
    users: Array<CheckboxOptionType | string>
    selectUsers?: string []
    disabled?: boolean
    onSelected?: (selectedValues) => void
}

export const UserGroup = ({ form, formFieldName, groupTitleId, users, selectUsers, disabled, onSelected }: UserGroupProps) => {
    return (
        <div className='reject-usergroup'>
            <div className='reject-usergroup-title'>
                <h5><FormattedMessage id={groupTitleId} /> </h5>
            </div>
            {
                form.getFieldDecorator(formFieldName, {initialValue: selectUsers} )(
                    <Checkbox.Group 
                        options = {users}
                        onChange = {onSelected}
                        disabled = {disabled}
                    />
                )
            }
        </div>
    )
}