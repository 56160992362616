import React from 'react'
import { Component } from 'react';
import { Form, Input, Modal } from "antd-min";
import { GLForm, GLFormProps, FormHelper, FormItemsLayout, GLGlobal, ComparisonOperator, MessageHelper, NotificationType } from 'gl-commonui';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { SubmitBtns } from '@app/components';
import { textareaValider, fmtMsg } from '@app/util';
import { UserGroup } from './usergroup';
import { MaterialService } from '@app/service/material';
import { Spin } from 'antd';
import { UserService } from '@app/service/users';
import { ValidationRule } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';

interface RejectModalProps extends GLFormProps {
    orderId: string
    visible: boolean
    onSave?: (d)=> void
    onCancel?: (e)=> void
}

interface RejectModalStates {
    loading: boolean
    userGroups: Map<string, any[]>
}

const userGroupsFieldName = new Map<string, string>([
    [SchoolLocale.MaterialOrderPagerRejectModalRequestParty, 'submitter'],
    [SchoolLocale.MaterialOrderPagerRejectModalShippingContact, 'shipContacts'],
    [SchoolLocale.MaterialOrderPagerRejectModalOtherCampusAdmins, 'campusAdmins'],
    [SchoolLocale.MaterialOrderPagerRejectModalOtherSchoolAdmins, 'schoolAdmins']
]);
const reopenedNotesFieldName = 'reopenedNotes';

@GLForm.create()
export class RejectModal extends React.Component<RejectModalProps, RejectModalStates> {
    private materialService: MaterialService
    private userService: UserService
    constructor(props) {
        super(props);
        this.materialService = new MaterialService();
        this.userService = new UserService();
        this.state = { 
            loading: false,
            userGroups: new Map<string, any[]>()
        };
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    
    componentWillReceiveProps(nextProps) {
        const { orderId, visible } = this.props;
        if (nextProps.visible && nextProps.visible != visible) {
            this.setUsers(orderId);
        }
        if (!nextProps.visible && nextProps.visible != visible) {
            this.resetState();
        }        
    }

    async setUsers(orderId) {
        this.setState({ loading: true });
        try {
            const concats = await this.materialService.getOrderContacts(orderId);
            const orderRequestUserIds = [concats.submitter],
                    orderShippingContacts = concats.shipContacts,
                    orderOtherCampusAdmins = concats.campusAdmins,
                    orderOtherSchoolAdmins = concats.schoolAdmins
            const {data: users} = await this.userService.getItemsBy({ids: orderRequestUserIds.concat(orderShippingContacts).concat(orderOtherCampusAdmins).concat(orderOtherSchoolAdmins)});
            this.setState({ 
                userGroups: new Map([
                    [SchoolLocale.MaterialOrderPagerRejectModalRequestParty, this.getUserGroup(users.filter(user=> orderRequestUserIds.includes(user.id)))],
                    [SchoolLocale.MaterialOrderPagerRejectModalShippingContact, this.getUserGroup(users.filter(user=> orderShippingContacts.includes(user.id)))],
                    // [SchoolLocale.MaterialOrderPagerRejectModalOtherCampusAdmins, this.getUserGroup(users.filter(user=> orderOtherCampusAdmins.includes(user.id)))],
                    // [SchoolLocale.MaterialOrderPagerRejectModalOtherSchoolAdmins, this.getUserGroup(users.filter(user=> orderOtherSchoolAdmins.includes(user.id)))]
                ]),
                loading: false
            });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    }

    resetState(callback?) {
        this.setState({ 
            userGroups: new Map<string, any[]>()
        }, ()=> callback && callback());
    }

    onSave(e) {
        e.preventDefault();
        const { form, onSave } = this.props;
        form.validateFields((err, values) => {
            let checkedNotificationRecipients = [];
            for (let fieldName of userGroupsFieldName.values())
            {
                values[fieldName] && checkedNotificationRecipients.push(...values[fieldName]);
            }
            if (checkedNotificationRecipients.length == 0) {
                MessageHelper.Message(NotificationType.Failed, fmtMsg(SchoolLocale.MaterialOrderPagerRejectModalFieldNotificationRecipientRequired));
            }
            if (!err && checkedNotificationRecipients.length > 0 && onSave) { 
                onSave({
                    notificationRecipients: checkedNotificationRecipients,
                    reopenedNotes: values[reopenedNotesFieldName]
                })
            }    
        });
    }

    onCancel(e) {
        const { onCancel } = this.props;
        if (onCancel) { 
            this.resetState(onCancel);
        };
    }

    getUserGroup(users: any[]) {
        return users.map( user => {
            return {
                label: user.name,
                value: user.id
            }
        });
    }

    renderUserGroups() {
        return [...this.state.userGroups.keys()].map((key, index) => {
            const users = this.state.userGroups.get(key);
            const disabled = key == SchoolLocale.MaterialOrderPagerRejectModalRequestParty || key == SchoolLocale.MaterialOrderPagerRejectModalShippingContact;
            const selectedUsers = disabled ? users.map(user => user.value) : [];
            return (
                users.length > 0 &&
                <Form.Item key={key}>
                    <UserGroup
                        form={this.props.form}
                        formFieldName={userGroupsFieldName.get(key)}
                        groupTitleId={key}
                        users={this.state.userGroups.get(key)}
                        disabled={disabled}
                        selectUsers={selectedUsers}
                    />
                </Form.Item>
            )
        });
    }

    render() {
        const form = this.props.form;
        const noteRules: ValidationRule[] = noteValidator(form);
        noteRules.push(
            {required: true, message: fmtMsg({id: SchoolLocale.MaterialOrderPagerRejectModalFieldReopenNoteRequired})}
        );
        return (
            <Modal
                title = {fmtMsg({id:SchoolLocale.MaterialOrderPagerRejectModalTitle})}
                closable = {false}
                footer = {null}
                className = {'reject-modal'}
                visible = { this.props.visible }
                destroyOnClose = {true}
            >
                <Spin spinning={this.state.loading}>
                    <GLForm form={form}   onSubmit={this.onSave}>
                        <div className='reject-notes'>
                            <h4 className='reject-notes-required'>
                                <FormattedMessage id={SchoolLocale.MaterialOrderPagerRejectModalFieldReopenNote} />
                            </h4>
                            <Form.Item>
                                {form.getFieldDecorator(reopenedNotesFieldName, {
                                    rules: noteRules,
                                    validateTrigger: 'onBlur'
                                })(
                                    <Input.TextArea placeholder={fmtMsg({id:SchoolLocale.MaterialOrderPagerRejectModalFieldReopenNotePlaceHolder})} />
                                )}
                            </Form.Item>
                        </div>
                        <div className='reject-usergroups'>
                            <h4>
                                <FormattedMessage id={SchoolLocale.MaterialOrderPagerRejectModalNotificationRecipients} />
                            </h4>
                            {this.renderUserGroups()}
                        </div>
                        <Form.Item>
                            <SubmitBtns submitTitle={SchoolLocale.MaterialOrderPagerButtonReject} onCancel={this.onCancel} />
                        </Form.Item>
                    </GLForm>
                </Spin>
            </Modal>
        )
    }
}

function lengthValidator(localeId, rigthVal) {
    return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
}

function noteValidator(form) {
    return textareaValider(form, reopenedNotesFieldName)
        .concat(lengthValidator(SchoolLocale.MaterialOrderPagerFieldShippedNotes, 250));
}
