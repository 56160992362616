import * as React from 'react'
import { Component } from 'react'
import { SchoolLocale } from "@app/locales/localeid";
import { MaterialNewPage } from './new';

export class CampusDraftOrderPage extends MaterialNewPage {
    constructor(props, context) {
        super(props, context);
    }

    static defaultProps = {
        saveButtonTextResourceId: SchoolLocale.BtnSave
    };
}