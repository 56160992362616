import * as React from "react";
import { Component } from "react";
import classNames from "classnames";
import { WijmoGrid } from "@app/components/grid";
import { SchoolLocale } from "@app/locales/localeid";
import {  HeadersVisibility, FlexGrid } from "wijmo/wijmo.grid";
import { CumulativeProductListDisplayPropsModel, CumulativeGridItemModel } from "@app/service/material";
import { fmtMsg } from "@app/util/func";

const { Grid, Column } = WijmoGrid;

interface MyComponentProps{
    orderId?: string
    dataSource?: CumulativeGridItemModel[];
    setGridRef?: (grid: FlexGrid) => void;
}

export class CumulativeProductGrid extends Component<MyComponentProps> {

    constructor(props: MyComponentProps) {
        super(props);
    }
    
    gridInitialized = flexGrid => {
        this.props.setGridRef(flexGrid);
    };

    getCumulativeListTitle(orderId: string): JSX.Element {
        return orderId ? <h3> {fmtMsg({ id: SchoolLocale.MaterialCumulativeItemListTitleWithOrderId }, { 0 : this.props.orderId })} </h3>
                       : <h3> {fmtMsg({ id: SchoolLocale.MaterialCumulativeItemListTitle })} </h3>
    }
    
    render() {
        const requestListClassName = {
            "material-request-grid": true,
        };          

        return(
            <>
                <div>
                    {this.getCumulativeListTitle(this.props.orderId)}
                </div>
                <Grid
                    stickyHeaders={true}
                    itemsSource={this.props.dataSource}
                    className={classNames(requestListClassName)}
                    headersVisibility={HeadersVisibility.All}
                    initialized={this.gridInitialized}
                    autoRowHeights={true}
                >
                    <Column
                        align="left"
                        binding={CumulativeProductListDisplayPropsModel.unitText}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialCumulativeProductItemFieldUnit
                        })}
                    />
                    <Column
                        binding={CumulativeProductListDisplayPropsModel.productName}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialCumulativeProductItemFieldProduct
                        })}
                        width="10*"
                    />
                    <Column
                        align="right"
                        binding={CumulativeProductListDisplayPropsModel.quantity}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialCumulativeProductItemFieldQuantity
                        })}
                        width="10*"
                    />
                    <Column
                        binding={CumulativeProductListDisplayPropsModel.price}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialCumulativeProductItemFieldPriceEach
                        })}
                        align="right"
                        width="10*"
                    />
                    <Column
                        binding={CumulativeProductListDisplayPropsModel.totalCost}
                        header={fmtMsg({
                           id: SchoolLocale.MaterialCumulativeProductItemFieldTotalPrice
                        })}
                        align="right"
                        width="10*"
                    />
                </Grid>
                <br/>
            </>
        )
    }
}   

