import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import moment from 'moment';
import { Form, Select, Input, message, DatePicker } from 'antd-min';
import { WrappedFormUtils, GetFieldDecoratorOptions } from "antd/lib/form/Form";
import { withRouter, GLGlobal, GLFormComponentProps, FormItemsLayout, GLAction, FormHelper, alignPop, GLForm, ComparisonOperator, LanguageDateFormat, beforeDate, GLUtil, PrimaryLanguageLocale } from 'gl-commonui';
import { SchoolLocale } from "@app/locales/localeid";
import { ThirdTitle, GLIcon, SubmitBtns, Container, Actions, Action, Loading } from '@app/components';
import { PurchaseRequestProps, SubscriptionClass, PurchaseRequest } from './components';
import { SchoolModel, CampusModel } from '@app/service/schools';
import { MaterialOrderModel, RequestProductItemModel, SubscriptionItemDisplayModel, MaterialOrdersPropsModel, MaterialRequestItemDisplayPropsModel, CumulativeGridItemModel} from '@app/service/material/model'
import { SubscriptionType } from "@app/service/class/model";
import { StateType } from '@app/states';
import { getMaterialOrder, formatMaterialCart, updateMaterialOrder } from '@app/states/material';
import { PurchaseTarget, DateHelper, subscriptionTypeUsage, GSSchoolAction, MaterialHelper, CommonHelper, textareaValider, fmtMsg, formatToMaterialRequestId, newLineRegex } from '@app/util';
import { FormattedMessage } from 'react-intl';
import { PathConfig } from '@app/config/pathconfig';
import { RejectIcon } from '@app/components/svgicon';
import { RejectModal } from './components/reject';
import { MaterialService } from '@app/service/material'
import { GLPdf, GLPdfColumn, GLPdfFlexGrid, GLPdfInfo, GLPdfLinebreak, GLPdfRow, GLPdfText, GLPdfTitle, GLPdfGroup, GLPdfPage } from "gl-pdf";
import { CellType, FlexGrid } from "wijmo/wijmo.grid";
import { wijmoGridResponsive } from '@app/util/wijmo';
import { getUnitText } from './util';
import { CumulativeProductGrid } from './components/CumulativeProductGrid';

interface CampusOrderProps extends RouteComponentProps<any> {
    loading?: boolean
    langLoaded?: string
    school: SchoolModel
    campus: CampusModel
    order: MaterialOrderModel
    subscriptions: SubscriptionItemDisplayModel[]
    purchases?: RequestProductItemModel[]
    cumulativeData?: CumulativeGridItemModel[]
    getMaterialOrder: (params) => void
    formatMaterialCart: (params) => void
    updateMaterialOrder: (params) => void
}

interface CampusOrderStates {
    isEdit: boolean
    isShppedDateRequired: boolean
    orderStatus: string
    shippedDateChanged: boolean
    errors: string[]
    clearShippedValue: boolean
    reopenModalVisible: boolean
    subscriptionGridRef: FlexGrid;
    purchaseGridRef: FlexGrid;
    cumulativeGrid: FlexGrid;
    pdfLoading: boolean;
}

@GLForm.create()
@withRouter
@connect(({intl: { langLoaded }, material: {loading, school, campus, order, subscriptions, purchases, cumulativeData}}: StateType)=>(
        {loading, langLoaded, school, campus, order, subscriptions, purchases, cumulativeData}), 
        { getMaterialOrder, formatMaterialCart, updateMaterialOrder }
    )
export class CampusOrderPage extends Component<CampusOrderProps & RouteComponentProps<any> & GLFormComponentProps, CampusOrderStates> {
    materialService: MaterialService
    constructor(props, context) {
        super(props, context);
        this.state = { 
            isEdit: false,
            isShppedDateRequired: false,
            orderStatus: SchoolLocale.MaterialOrderStatusSubmitted,
            shippedDateChanged: false,
            errors: [],
            clearShippedValue: false,
            reopenModalVisible: false,
            subscriptionGridRef: null,
            purchaseGridRef: null,
            cumulativeGrid: null,
            pdfLoading: false
        };
        this.startEdit = this.startEdit.bind(this);
        this.endEdit = this.endEdit.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onShippedDateChanged = this.onShippedDateChanged.bind(this);
        this.onSave = this.onSave.bind(this);
        this.setCumulativeGrid = this.setCumulativeGrid.bind(this);
        this.materialService = new MaterialService();
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.langLoaded != nextProps.langLoaded) {
            this.props.formatMaterialCart({materialCart: null});
        }
        const orderId = nextProps.order.id;
        if (nextProps.order && orderId && MaterialHelper.MaterialOrderStatus[nextProps.order.status] == SchoolLocale.MaterialOrderStatusDraft ) {
            const { regionId, schoolId, campusId } = this.props.match.params;
            const orderPath = regionId && schoolId && campusId && orderId ? GLUtil.pathStringify(PathConfig.CampusOrdersDraftItem, { regionId, schoolId, campusId, orderId }) : '';
            this.props.history.push(orderPath)
        }
    }
    componentDidMount() {
        const {schoolId, campusId, orderId} = this.props.match.params;
        this.props.getMaterialOrder({schoolId, campusId, orderId});
    }
    startEdit() {
        const {order} = this.props;
        this.setState({
            isEdit: true,
            isShppedDateRequired: this.needCheckShippedQuantity(order.status),
            orderStatus: MaterialHelper.MaterialOrderStatus[order.status],
            shippedDateChanged: false,
            errors: [],
            clearShippedValue: false,
            reopenModalVisible: false
        });
    }
    endEdit() {
        const {schoolId, campusId, orderId} = this.props.match.params;
        this.setState({isEdit: false}, () => this.props.getMaterialOrder({schoolId, campusId, orderId}));
    }
    needCheckShippedQuantity(orderStatus) {
        return MaterialHelper.MaterialOrderStatus[orderStatus] == SchoolLocale.MaterialOrderStatusShipped ||
            MaterialHelper.MaterialOrderStatus[orderStatus] == SchoolLocale.MaterialOrderStatusClosed;
    }
    canEditShippedData(orderStatus) {
        return orderStatus == SchoolLocale.MaterialOrderStatusShipped ||
                orderStatus == SchoolLocale.MaterialOrderStatusClosed;
    }    
    onStatusChanged(status) {
        const {form:{getFieldError, resetFields, setFieldsValue}} = this.props;
        if (getFieldError(MaterialOrdersPropsModel.shippedDate)) {resetFields([MaterialOrdersPropsModel.shippedDate]);}
        this.setState({
            orderStatus: MaterialHelper.MaterialOrderStatus[status],
            isShppedDateRequired: this.needCheckShippedQuantity(status),
            clearShippedValue: MaterialHelper.MaterialOrderStatus[status] == SchoolLocale.MaterialOrderStatusDraft
        });
        if (MaterialHelper.MaterialOrderStatus[status] == SchoolLocale.MaterialOrderStatusDraft) {
            setFieldsValue({ 
                shippedDate: null,
                shippedNotes: null
            });
        }
    }
    onShippedDateChanged() {
        this.setState({shippedDateChanged: true});
    }
    isShippedQuantityEmpty() {
        const { subscriptions, purchases, form } = this.props;
        const result = this.needCheckShippedQuantity(form.getFieldValue(MaterialOrdersPropsModel.status)) && 
            (subscriptions.filter(subscription=> !subscription.shippedQuantity).length > 0 ||
                purchases.filter(purchase=> !purchase.shippedQuantity).length > 0);
        return result;
    }
    onSave(e) {
        const { form, form: {getFieldValue}, subscriptions, purchases, match: {params: {schoolId, orderId} }, location: {search} } = this.props;
        const needCheckQuantity = this.needCheckShippedQuantity(getFieldValue(MaterialOrdersPropsModel.status));
        //const subscriptionIds = subscriptions.map(subscription=> subscription.requestId);
        //const purchaseIds = purchases.map(purchase=>purchase.id);
        const isShippedQuantityEmpty = needCheckQuantity && (subscriptions.filter(subscription=> subscription.shippedQuantity == null).length > 0
                    || purchases.filter(purchase=> purchase.shippedQuantity  == null ).length > 0);
        const shippedDate = getFieldValue(MaterialOrdersPropsModel.shippedDate);
        const params2 = new URLSearchParams(search);
        const from = params2.get('from');
        e.preventDefault();
        form.validateFieldsAndScroll((error, values) => {
            if( isShippedQuantityEmpty || (this.state.errors.length > 0) ) {
                message.error(fmtMsg({id: SchoolLocale.MaterialOrderShippedQuantityErrorEmpty}));
                return;
            }
            if (!error) {
                const updatedValues = {
                    orderId: orderId,
                    status: parseInt(getFieldValue(MaterialOrdersPropsModel.status), 10),
                    shippedDate: shippedDate ? DateHelper.formatDate(shippedDate, 'YYYY-MM-DD') : null,
                    shippedNotes: getFieldValue(MaterialOrdersPropsModel.shippedNotes),
                    subscriptionShippedQuantities: subscriptions.map(subscription=> {return {
                        requestId: subscription.requestId,
                        requestQuantity: subscription.shippedQuantity
                    }}),
                    purchaseShippedQuantities: purchases.map(purchase=> {return {
                        requestId: purchase.id,
                        requestQuantity: purchase.shippedQuantity
                    }}),
                    from
                };
                this.props.updateMaterialOrder(updatedValues);
            }
        });
    }
    onReopenOrder({ notificationRecipients, reopenedNotes }) {
        const { match: {params: {orderId} }, location: {search}, subscriptions, purchases} = this.props;
        const params2 = new URLSearchParams(search);
        const from = params2.get('from');
        const purchaseIds = purchases.map(purchase=>purchase.id);
        const subscriptionIds = subscriptions.map(subscription=> subscription.requestId);
        const updatedValues = {
            orderId: orderId,
            status: parseInt(MaterialHelper.draftKey, 10),
            notificationRecipients: notificationRecipients,
            reopenedNotes: reopenedNotes,
            subscriptionShippedQuantities: subscriptionIds.map(requestId=> {return {
                requestId: requestId,
                requestQuantity: null
            }}),
            purchaseShippedQuantities: purchaseIds.map(id=> {return {
                requestId: id,
                requestQuantity: null
            }}),            
            from
        };
        this.props.updateMaterialOrder(updatedValues);
    }

    public setSubscriptionGrid(grid: FlexGrid): void {
        this.setState({ subscriptionGridRef: grid });
    }
    public setPurchaseGrid(grid: FlexGrid): void {
        this.setState({ purchaseGridRef: grid });
    }
    public setCumulativeGrid(grid: FlexGrid): void {
        this.setState({ cumulativeGrid: grid });
    }

    print() {
        this.setState({ pdfLoading: true });
        const { order, school } = this.props;
        const columnOptions = { width: 33 };
        const [address1, address2] = getAddressInTwoLine(order);

        const pdf = new GLPdf({ title: fmtMsg({ id: SchoolLocale.MaterialOrderPageTitle }), locale: this.props.langLoaded as PrimaryLanguageLocale });
        setTimeout(() => {
            pdf.build(
                new GLPdfTitle({ title: fmtMsg({ id: SchoolLocale.MaterialOrderPageTitle }) }),
                new GLPdfLinebreak(),
                new GLPdfRow(
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialOrderPagerFieldOrderId
							}),
							value: formatToMaterialRequestId(order.materialRequestId)
						})
                    ]),
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialOrderPagerFieldSubmissionDate
							}),
							value: DateHelper.formatDate2Local(order.submissionDate)
						})
                    ]),
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialOrderPagerFieldGSNumber
							}),
							value: school.gsNumber
						})
                    ]),
                ),
                new GLPdfRow(
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialRequestFieldSubmissionUser
							}),
							value: order.submissionUser
						})
                    ]),
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialOrderPagerFieldSubscription
							}),
							value: SubscriptionType[school.subscriptionType]
						})
                    ]),
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialRequestFieldSubmissionEmail
							}),
							value: order.submissionEmail,
							link: `mailto:${order.submissionEmail}`
						})
                    ]),
                ),
                new GLPdfRow(
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialOrderPagerFieldUsage
							}),
							value: subscriptionTypeUsage[school.subscriptionTypeUsage]
						})
                    ]),
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialRequestFieldSubmissionPhone
							}),
							value: order.submissionPhone
						})
                    ]),
                    new GLPdfColumn(columnOptions, [
						new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialOrderPagerFieldShipDate
							}),
							value: order.shipDate ? DateHelper.formatDate2Local(order.shipDate) : " "
						})
                    ]),
                ),
                new GLPdfRow(
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialOrderPagerFieldShippedDate
							}),
							value: DateHelper.formatDate2Local(order.shippedDate)
						})
                    ]),
                    new GLPdfColumn(columnOptions, [
                        new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialOrdersItemFieldStatus
							}),
							value: !!order.status
								? fmtMsg({
										id: MaterialHelper.MaterialOrderStatus[order.status]
								  })
								: ""
						})
                    ]),
                ),
                new GLPdfLinebreak(),
                new GLPdfTitle({ title: fmtMsg({ id: SchoolLocale.MaterialRequestFieldShipTitle }) }),
                new GLPdfLinebreak(),
                new GLPdfRow(
                    new GLPdfColumn(columnOptions, [
						new GLPdfInfo({
							label: fmtMsg({ id: SchoolLocale.MaterialRequestFieldCampus }),
							value: order.campusName
						})
					]),
                    new GLPdfColumn(columnOptions, [
						new GLPdfInfo({
							label: fmtMsg({ id: SchoolLocale.MaterialRequestFieldName }),
							value: order.shippingName
						})
					]),
                    new GLPdfColumn(columnOptions, [
						new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialRequestFieldPostCode
							}),
							value: order.postalCode
						})
					])
                ),
                new GLPdfRow(
                    new GLPdfColumn(columnOptions, [
						new GLPdfInfo({
							label: fmtMsg({ id: SchoolLocale.MaterialRequestFieldEmail }),
							value: order.shippingEmail,
							link: `mailto:${order.shippingEmail}`
						})
					]),
                    new GLPdfColumn(columnOptions, [
						new GLPdfInfo({
							label: fmtMsg({
								id: SchoolLocale.MaterialRequestFieldShippingAddress
							}),
							value: `${address1} \n${address2}`
						})
					]),
                    new GLPdfColumn(columnOptions, [
						new GLPdfInfo({
							label: fmtMsg({ id: SchoolLocale.MaterialRequestFieldPhone }),
							value: order.shippingPhone
						})
					])
                ),
                new GLPdfLinebreak(),
                this.state.subscriptionGridRef &&
                    new GLPdfGroup(
                        new GLPdfLinebreak(),
                        new GLPdfTitle({
							title: fmtMsg({
								id: SchoolLocale.MaterialSubscriptionItemListTitle
							})
						}),
                        new GLPdfFlexGrid(this.state.subscriptionGridRef, {
                            afterDraw: wijmoGridResponsive,
                            formatItem: (args) => {
                                
                                if(args.panel.cellType === CellType.Cell) {
                                    const column = args.panel.columns[args.col];
                                    if(column.binding === MaterialRequestItemDisplayPropsModel.unit) {
                                        const dataItem = args.panel.rows[args.row].dataItem;
                                        args.data = getUnitText(dataItem[column.binding]);
                                    }
                                    if(column.binding === MaterialRequestItemDisplayPropsModel.licenseHistory) {
                                        args.data = null;                                    
                                    }
                                }
                                
                            }
                        }),
                        new GLPdfLinebreak()
                    ),
                this.state.purchaseGridRef && 
                    new GLPdfGroup(
                        new GLPdfTitle({ title: fmtMsg({ id: SchoolLocale.MaterialPurchaseItemListTitle }) }),
                        new GLPdfFlexGrid(this.state.purchaseGridRef, {
                            afterDraw: wijmoGridResponsive,
                            formatItem: (args) => {
                                if(args.panel.cellType === CellType.Cell) {
                                    const column = args.panel.columns[args.col];
                                    if(column.binding === MaterialRequestItemDisplayPropsModel.unit) {
                                        const dataItem = args.panel.rows[args.row].dataItem;
                                        args.data = getUnitText(dataItem[column.binding]);
                                    }
                                }
                            }
                        }),
                        new GLPdfLinebreak(),
                    ),
                    
                    this.state.cumulativeGrid && 
                    new GLPdfGroup(
                        new GLPdfTitle({ title: fmtMsg({ id: SchoolLocale.MaterialCumulativeItemListTitleWithOrderId }, { 0 : formatToMaterialRequestId(order.materialRequestId) })}),
                        new GLPdfFlexGrid(this.state.cumulativeGrid, {
                            afterDraw: wijmoGridResponsive,
                            formatItem: (args) => {
                                if(args.panel.cellType === CellType.Cell) {
                                    const column = args.panel.columns[args.col];
                                    if(column.binding === MaterialRequestItemDisplayPropsModel.unit) {
                                        const dataItem = args.panel.rows[args.row].dataItem;
                                        args.data = getUnitText(dataItem[column.binding]);
                                    }
                                }
                            }
                        }),
                        new GLPdfLinebreak(),
                    ),

                    order.notes &&
                    new GLPdfGroup(
                        new GLPdfTitle({ title: fmtMsg({ id: SchoolLocale.MaterialRequestFieldNote }) }),
                        new GLPdfText(order.notes),
                        new GLPdfLinebreak(),
                    ),
                order.shippedNotes &&
                    new GLPdfGroup(
                        new GLPdfTitle({
							title: fmtMsg({
								id: SchoolLocale.MaterialOrderPagerFieldShippedNotes
							})
						}),
                        new GLPdfText(order.shippedNotes),
                    ),
            ).download(`${fmtMsg({ id: SchoolLocale.MaterialOrderPageTitle })} - ${formatToMaterialRequestId(order.materialRequestId)}`).onEnded(() => {
                this.setState({ pdfLoading: false });
            });
        }, 300);
    }

    render() {
        const {schoolId, orderId} = this.props.match.params;
        let campusId = this.props.match.params.campusId;
        const { school, campus, order, subscriptions, purchases, cumulativeData, match: {params}, form, history, location, match } = this.props;
        const { clearShippedValue, isEdit, isShppedDateRequired, orderStatus, reopenModalVisible, shippedDateChanged } = this.state;
        const purchaseProps: PurchaseRequestProps = {
            canEdit: false,
            form: form,
            locationParams: params,
            purchaseTarget: PurchaseTarget.School,
            requestTitleId: SchoolLocale.MaterialPurchaseItemListTitle,
            requests: purchases,
            needClassColumn: true,
            needPriceColumn: true,
            needShippedQuantityColumn: true,
            clearShippedValue: clearShippedValue,
            dataSource: purchases,
            isGridEditing: isEdit,
            shippedQuantityIsRequired: isShppedDateRequired,
            history,
            location,
            match,
            needTotalPriceColumn: true,
            needTotalRow: true,
            getRequestItem: this.materialService.getPurchaseItem.bind(this.materialService),
            productDataParams: {schoolId, campusId, orderId, subscriptionType: school.subscriptionType},
            showNoteColumn: true
        }
        //const orderDate = order && order.submissionDate ? `(${DateHelper.formatDate2Local(order.submissionDate)})` : ''
        const title = `${fmtMsg({id:SchoolLocale.MaterialOrderPageTitle})}`;
        const canEditShippedData = isEdit && this.canEditShippedData(orderStatus);
        return (
            <Container fullWidth>
                <Loading className="material-order__pdfloader" visible={this.state.pdfLoading} text={fmtMsg(SchoolLocale.MaterialOrderPagerPDFMessage)} spinProps={{ size: 'large' }} />
                <div className="material material-order">
                    <div className='material-order-header'>
                        <div className='material-order-header-title'>
                            <h1>{title}</h1>
                            {!campus.disabled && !isEdit && 
                                <GLAction action={GSSchoolAction.EditOrder}>
                                <GLIcon type='edit' labelId={SchoolLocale.IconEdit} onClick={this.startEdit} />
                                </GLAction>
                            }
                        </div>
                        {!campus.disabled && !isEdit && GLGlobal.isActionValid(GSSchoolAction.EditOrder) &&
                        <div className='material-order-header-reopen-order'>
                            <Actions>
                                <Action materialIcon="picture_as_pdf" textLocaleId={SchoolLocale.MaterialOrderPagerButtonDownload} onClick={this.print.bind(this)} />
                                <Action className="red" materialIcon="lock_open" textLocaleId={SchoolLocale.MaterialOrderPagerButtonReject} onClick={(e) => { e.preventDefault(); this.setState({ reopenModalVisible: true }) }} />
                            </Actions>
                        </div>
                        }
                        <RejectModal 
                            orderId={params.orderId}
                            visible={reopenModalVisible}
                            onSave={this.onReopenOrder.bind(this)}
                            onCancel={(e) => { this.setState({ reopenModalVisible: false }) }}
                        />
                    </div>
                    <form onSubmit={this.onSave} className='material-order-form'>
                        <OrderRequest 
                            school={school} 
                            order={order} 
                            form={form} 
                            isEdit={isEdit} 
                            isShppedDateRequired={isShppedDateRequired} 
                            shippedDateChanged={shippedDateChanged}
                            onStatusChanged={this.onStatusChanged}
                            onShippedDateChanged={this.onShippedDateChanged}
                        />
                        <ShippingAddress order={order} />
                        { subscriptions.length > 0 && 
                            <SubscriptionClass 
                                canEdit={false} 
                                form={form} 
                                canEditQuantity={isEdit} 
                                needShippedQuantityColumn={true} 
                                locationParams={params}
                                clearShippedValue={clearShippedValue}
                                dataSource={subscriptions}
                                isGridEditing={isEdit}
                                shippedQuantityIsRequired={isShppedDateRequired}
                                history={history}
                                needTotalRow={true}
                                setGridRef={this.setSubscriptionGrid.bind(this)}
                            />
                        }
                        {school.hasPurchaseProduct && purchases.length > 0 && <PurchaseRequest {...{...purchaseProps, canEditQuantity: isEdit}} setGridRef={this.setPurchaseGrid.bind(this)} />}
                        <CumulativeProductGrid orderId={formatToMaterialRequestId(order.materialRequestId)} setGridRef={this.setCumulativeGrid} dataSource={this.props.cumulativeData} />
                        <div className="order-note">
                            <h4><FormattedMessage id={SchoolLocale.MaterialRequestFieldNote} /></h4>
                            {order.notes? getNotes(order.notes) : null}
                        </div>
                        <div className="order-note">
                            <OrderNotes form={form} isEdit={isEdit} notes={order.shippedNotes} />
                        </div>
                        {isEdit && <SubmitBtns onCancel={this.endEdit} />}
                    </form>
                </div>
            </Container>
        );
    }
}

function getNotes(notes) {
    return <pre>{notes}</pre>;
    // return notes.split('\n').map((note, index) => {
    //     return <p key={index}>{note}</p>
    // })
}

function lengthValidator(localeId, rigthVal) {
    return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
}

function noteValidator(form) {
    return textareaValider(form, MaterialOrdersPropsModel.shippedNotes).concat(lengthValidator(SchoolLocale.MaterialOrderPagerFieldShippedNotes, 250));
}

const renderFormItem = FormHelper.renderFormItem;
const FormItem = Form.Item;

const formItemLayoutLeft = {
    labelCol: {xm:{span:5}, md:{span:10}},
    wrapperCol: {xm:{span:7}, md:{span:14}}
};
 const formItemLayoutRight = {
    labelCol: {xm:{span:5}, md:{span:9}},
    wrapperCol: {xm:{span:7}, md:{span:14}}
};

const OrderRequest = (props:{school: SchoolModel, 
        order: MaterialOrderModel, form: WrappedFormUtils, isEdit: boolean, 
        isShppedDateRequired: boolean, shippedDateChanged: boolean,
        onStatusChanged: (status) => void,
        onShippedDateChanged: (date: any, dateString: string) => void}): JSX.Element => {
    const {school, order, form, isEdit, isShppedDateRequired, shippedDateChanged, onStatusChanged, onShippedDateChanged} = props;
    const FormItem = Form.Item;
    return (
        <div className='request-info'>
            <FormItemsLayout colTotal={2}>
                <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialOrderPagerFieldOrderId})}>
                    <span className="ant-form-text">{formatToMaterialRequestId(order.materialRequestId)}</span>
                </FormItem>
                <FormItem {...formItemLayoutRight} label={fmtMsg({id:SchoolLocale.MaterialOrderPagerFieldSubmissionDate})}>
                    <span className="ant-form-text">{DateHelper.formatDate2Local(order.submissionDate)}</span>
                </FormItem>
            </FormItemsLayout>
            <FormItemsLayout colTotal={2}>
                <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialOrderPagerFieldGSNumber})}>
                    <span className="ant-form-text">{school.gsNumber}</span>
                </FormItem>
                <FormItem {...formItemLayoutRight} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldSubmissionUser})}>
                    <span className="ant-form-text">{order.submissionUser}</span>
                </FormItem>
            </FormItemsLayout>                
            <FormItemsLayout colTotal={2}>
                <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialOrderPagerFieldSubscription})}>
                    <span className="ant-form-text">{SubscriptionType[school.subscriptionType]}</span>
                </FormItem>
                <FormItem {...formItemLayoutRight} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldSubmissionEmail})}>
                    <span className="ant-form-text">{order.submissionEmail}</span>
                </FormItem>               
            </FormItemsLayout>
            <FormItemsLayout colTotal={2}>
                <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialOrderPagerFieldUsage})}>
                    <span className="ant-form-text">{subscriptionTypeUsage[school.subscriptionTypeUsage]}</span>
                </FormItem>
                <FormItem {...formItemLayoutRight} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldSubmissionPhone})}>
                    <span className="ant-form-text">{order.submissionPhone}</span>
                </FormItem>               
            </FormItemsLayout>
            <FormItemsLayout colTotal={2}>
                <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialOrderPagerFieldShipDate})}>
                    <span className="ant-form-text">{order.shipDate ? DateHelper.formatDate2Local(order.shipDate) : ' '}</span>
                </FormItem>
                <ShippedDate 
                    form={form} 
                    isEdit={isEdit} 
                    isShppedDateRequired={isShppedDateRequired} 
                    shippedDate={order.shippedDate} 
                    requestShipDate={order.shipDate} 
                    shippedDateChanged={shippedDateChanged}
                    onShippedDateChanged={onShippedDateChanged}
                />
            </FormItemsLayout>
            <FormItemsLayout colTotal={2}>
                <OrderStatus form={form} isEdit={isEdit} status={order.status} onStatusChanged={onStatusChanged} />
                <FormItem {...formItemLayoutRight} label='' />
            </FormItemsLayout>
        </div>         
    )
}

const getAddressInTwoLine = (order: MaterialOrderModel) => {
    const address = order.address ? order.address.split("\n") : ["", ""];
    const address1 = address.length > 0 ? address[0] : "";
    const address2 = address.length > 1 ? address[1] : "";
    return [address1, address2];
};

const ShippingAddress = (props: {order: MaterialOrderModel}): JSX.Element => {
    const {order} = props;
    const FormItem = Form.Item;
    const refactoredAddress = order.address ? order.address.split(newLineRegex) : [];
    const addressDisplay = refactoredAddress.map(addressFragment => (<span>{addressFragment}<br/></span>))
    return (
        <div className='shipping-address'>
            <ThirdTitle title={SchoolLocale.MaterialRequestFieldShipTitle} />
            <FormItemsLayout colTotal={2}>
                <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldCampus})}>
                    <span className="ant-form-text">{order.campusName}</span>
                </FormItem>
                <FormItem {...formItemLayoutRight} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldName})}>
                    <span className="ant-form-text">{order.shippingName}</span>
                </FormItem>
            </FormItemsLayout>
            <FormItemsLayout colTotal={2}>
                <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldPostCode})}>
                    <span className="ant-form-text">{order.postalCode}</span>
                </FormItem>
                <FormItem {...formItemLayoutRight} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldEmail})}>
                    <span className="ant-form-text">{order.shippingEmail}</span>
                </FormItem> 
            </FormItemsLayout>
            <FormItemsLayout colTotal={2}>
                <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldShippingAddress})}>
                    <div className='readonly'><p>{addressDisplay}</p></div>
                </FormItem>
                <FormItem {...formItemLayoutRight} label={fmtMsg({id:SchoolLocale.MaterialRequestFieldPhone})}>
                    <span className="ant-form-text">{order.shippingPhone}</span>
                </FormItem>
            </FormItemsLayout>
        </div>         
    )
}

const ShippedDate = (props: {form: WrappedFormUtils, 
        isEdit: boolean, isShppedDateRequired: boolean, 
        shippedDate: string, requestShipDate: string, shippedDateChanged: boolean,
        onShippedDateChanged: (date: any, dateString: string) => void}) => {
    const {form, isEdit, isShppedDateRequired, shippedDate, requestShipDate, shippedDateChanged, onShippedDateChanged} = props;
    const FormItem = Form.Item;
    const extendForm = { ...form, colon: true };
    if (isEdit) {
        let rules = [];
        if (isShppedDateRequired) {
            rules.push({
                required: true,
                message: fmtMsg({id:SchoolLocale.MaterialOrderShippedDateErrorEmpty})
            });
        }
        const format = LanguageDateFormat[GLGlobal.intl.locale];
        const defaultPickerValue = requestShipDate ? moment(requestShipDate) : moment();
        const disabledDate = requestShipDate ? beforeDate(moment(requestShipDate).add(-1, 'days').endOf('day')) : beforeDate(moment().add(-1, 'days').endOf('day'))
        return (
            <FormItem className='material-order-editable-label'>
                {renderFormItem(extendForm, SchoolLocale.MaterialOrderPagerFieldShippedDate, MaterialOrdersPropsModel.shippedDate, 
                    <DatePicker {...alignPop({ type: 'DatePicker', querySelector: '.material-order-form' })} 
                        format={format}
                        onChange={onShippedDateChanged}
                    />, shippedDate && !shippedDateChanged ? moment(shippedDate) : null, false, rules, formItemLayoutRight)}
            </FormItem>
        )   
    }
    else {
        return (
            <FormItem {...formItemLayoutRight} label={fmtMsg({id:SchoolLocale.MaterialOrderPagerFieldShippedDate})}>
                <span className="ant-form-text">{DateHelper.formatDate2Local(shippedDate)}</span>
            </FormItem> 
        )
    }
}

const OrderStatus = (props: {form: WrappedFormUtils, isEdit: boolean, status: string, onStatusChanged: (status) => void}) => {
    const {form: {getFieldDecorator}, isEdit, status, onStatusChanged} = props;
    if (isEdit) {
        return (
            <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialOrdersItemFieldStatus})} className='material-order-editable-label'>
                {getFieldDecorator(`${MaterialOrdersPropsModel.status}`, { initialValue: `${status}`, })
                    (
                        <Select size='large' {...alignPop()} onChange={onStatusChanged}>
                            {MaterialHelper.getMaterialOrderStatus(true).map(orderStatus=> CommonHelper.getOption(orderStatus.key, orderStatus.key, fmtMsg({id: orderStatus.value}))) }
                        </Select>
                    )
                }
            </FormItem>
        )   
    }
    else {
        return (
            <FormItem {...formItemLayoutLeft} label={fmtMsg({id:SchoolLocale.MaterialOrdersItemFieldStatus})}>
                <span className="ant-form-text">{!!status ? fmtMsg({id: MaterialHelper.MaterialOrderStatus[status]}) : ''}</span>
            </FormItem>
        )
    }
}

const OrderNotes = (props: {form: WrappedFormUtils, isEdit: boolean, notes: string}) => {
    const {form, isEdit, notes} = props;
    if (isEdit) {
        return (
            <React.Fragment>
                <h4><FormattedMessage id={SchoolLocale.MaterialOrderPagerFieldShippedNotes} /></h4>
                <FormItem>
                    {form.getFieldDecorator(MaterialOrdersPropsModel.shippedNotes, {
                        initialValue: notes,
                        rules: noteValidator(form),
                        validateTrigger: 'onBlur'
                    })(
                        <Input.TextArea />
                    )}
                </FormItem>                 
            </React.Fragment>           
        )
    }
    else {
        return (
            <React.Fragment>
                <h4><FormattedMessage id={SchoolLocale.MaterialOrderPagerFieldShippedNotes} /></h4>
                {notes? getNotes(notes) : null}
            </React.Fragment>
        )
    }    
}